<template>
    <div class="contain">
        <div class="contain-player">
            <div class="player-header">
                <img :src="currentPersonnel?currentPersonnel.avatarUrl:''"
                     alt="">
            </div>
            <div class="player-title">参赛者: {{currentPersonnel?currentPersonnel.name:'暂无选手'}}</div>
            <div class="player-about">
                参赛者简介: {{currentPersonnel?currentPersonnel.descContent:'暂无简介'}}
            </div>
        </div>
        <div class="contain-judge" v-if="eventInfo.scoreStandard===1">
            <div class="judge-table">
                <el-table
                        :data="judgeList"
                        :header-cell-style="{ background:'#b14040',color:'#fff'}"
                        border
                        style="width: 100%">
                    <el-table-column label="评委编号" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.judgeCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="打分" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.eventJudgeScore?scope.row.eventJudgeScore.scoreA:'未打分'}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="contain-judge" v-if="eventInfo.scoreStandard===2">
            <div class="judge-table">
                <el-table
                        :data="judgeList"
                        :header-cell-style="{ background:'#b14040',color:'#fff'}"
                        border
                        style="width: 100%">
                    <el-table-column label="评委编号" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.judgeCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="基础" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.eventJudgeScore?scope.row.eventJudgeScore.scoreC:'未打分'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创新" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.eventJudgeScore?scope.row.eventJudgeScore.innovationScoreB:'未打分'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="难度" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.eventJudgeScore?scope.row.eventJudgeScore.difficultyScoreB:'未打分'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="破纪录" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.eventJudgeScore?scope.row.eventJudgeScore.recordScoreB:'未打分'}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

      <div class="contain-judge" v-if="eventInfo.scoreStandard===3">
        <div class="judge-table">
          <div class="tab-top">
            <div style="width: 33%;display: flex;align-items: center;justify-content: center;color: #fff">
              评委编号
            </div>
            <div style="width: 33%;display: flex;align-items: center;justify-content: center;color: #fff">
              姓名
            </div>
            <div style="width: 33%;display: flex;align-items: center;justify-content: center;color: #fff">
              打分
            </div>
          </div>
          <div class="tab-content" v-for="item in judgeList" :key="item">
            <div style="width: 33%;display: flex;align-items: center;justify-content: center;color: #b24040">
              {{item.judgeCode}}
            </div>
            <div style="width: 33%;display: flex;align-items: center;justify-content: center;color: #b24040">
              {{item.name}}
            </div>
            <div style="width: 33%;display: flex;align-items: center;justify-content: center;color: #b24040">
              {{item.eventJudgeScore?item.eventJudgeScore.scoreC:'未评分'}}
            </div>
          </div>
          <div class="tab-content">
            <div style="width: 100%; display: flex;align-items: center;justify-content: center;color: #b24040">
              <span style="margin-right: 50px;box-sizing: border-box">评委评分</span>
              <span>{{avgScore2}}</span>
            </div>
          </div>
          <div class="tab-content">
            <div style="width: 100%; display: flex;align-items: center;justify-content: center;color: #b24040">
              <span style="margin-right: 50px;box-sizing: border-box">观众评分</span>
              <span>{{avgScore1}}</span>
            </div>
          </div>
          <div class="tab-content">
            <div style="width: 100%; display: flex;align-items: center;justify-content: center;color: #b24040">
              <span style="margin-right: 50px;box-sizing: border-box">最终得分</span>
              <span>{{finalScore}}</span>
            </div>
          </div>


        </div>
      </div>

      <div class="contain-set">
            <div class="set-box">
                <span style="font-size: 20px;color: #000;font-weight: bold">大屏控制</span>
                <!--                <el-checkbox-group v-model="checkboxGroup3" size="small">-->
                <!--                    <el-checkbox-button  v-for="item in cityOptions" :label="item.id"  :key="item.id" style="margin: 0 20px;">{{item.name}}</el-checkbox-button>-->
                <!--                </el-checkbox-group>-->
                <el-radio-group v-model="radio" size="small" @change="changeStartShow">
                    <el-radio-button v-for="item in cityOptions" :label="item.id" :key="item.id"
                                     style="margin: 0 20px;box-sizing: border-box">{{item.name}}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="contain-set">
            <div class="set-box">
<!--                <el-button style="background-color: #bf353b;color: #fff" size="small">-->
<!--                    上一个选手-->
<!--                </el-button>-->
                <el-button style="background-color: #bf353b;color: #fff" size="small" @click="lastPersonnel">
                    下一个选手
                </el-button>
                <el-button style="background-color: #bf353b;color: #fff" size="small" @click="getInEventOver">
                    结束比赛
                </el-button>
            </div>
        </div>
        <div class="contain-judge">
            <div class="judge-table">
                <el-table
                        :data="personnelList"
                        :header-cell-style="{ background:'#b14040',color:'#fff'}"
                        border
                        style="width: 100%">
                    <el-table-column label="序号" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.sort}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="头像" align="center">
                        <template slot-scope="scope">
                            <img :src="scope.row.avatarUrl"
                                 style="width: 50px;height: 50px;border-radius: 50%"
                                 alt="">
                        </template>
                    </el-table-column>
                    <el-table-column label="参赛状态" align="center">
                        <template slot-scope="scope">
                            <span style="color: #b14040">{{scope.row.abstentionStatus===1?'已完赛':scope.row.abstentionStatus===0?'待比赛':scope.row.abstentionStatus===2?'已弃权':'进行中'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <el-button @click="abstention(scope.row.id)" type="text" :disabled="!(scope.row.abstentionStatus!==1 && scope.row.abstentionStatus!==2)">弃权</el-button>
                            <el-button @click="changePosition(scope.row.id,1)" type="text" :disabled="!(scope.$index!==0 && scope.row.abstentionStatus!==1&& scope.row.abstentionStatus!==2)">提前</el-button>
                            <el-button @click="changePosition(scope.row.id,0)" type="text" :disabled="!(scope.$index!==personnelList.length-1&& scope.row.abstentionStatus!==1&& scope.row.abstentionStatus!==2)">退后</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

    </div>
</template>

<script>
    import {findById,startEventInfo,changeStartShow,lastPersonnel,abstention,validEventOver} from "../../api/event";
    import {changePosition} from "../../api/personnel";

    export default {
        name: "eventControl",
        data() {
            return {
                tableData: [],
                radio: null,
                cityOptions: [
                    {
                        id: 1,
                        name: '比赛封面',
                    },
                    {
                        id: 2,
                        name: '选手信息',
                    },
                    {
                        id: 3,
                        name: '排名信息',
                    },
                    {
                        id: 4,
                        name: '选手顺序',
                    },
                    {
                        id: 5,
                        name: '打分信息',
                    }
                ],
                roleFlag:null,
                eventInfo: null,
                //当前选手信息
                currentPersonnel:null,
                //选手列表
                personnelList:[],
                //评委打分信息
              judgeList:[],
              //观众平均分
              avgScore1:null,
              //评委平均分
              avgScore2:null,
              //最终得分
              finalScore:null
            }
        },
        mounted() {
            //1管理 2评委 3主持 4大屏端
            this.roleFlag = sessionStorage.getItem('roleFlag');
        },
        created() {
            this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
            this.getEventInfo(this.eventInfo.id)
            this.startEventInfo(this.eventInfo.id)
        },
        methods: {
           abstention(id){
            this.$confirm('当前选手弃权, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              const {data}= await abstention(id)
              if(data.code===200){
                await this.startEventInfo(this.eventInfo.id)
                this.$message.success('操作成功')
              }
            })
          },
          lastPersonnel(){
            this.$confirm('切换下一个选手, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              const {data} = await lastPersonnel(this.eventInfo.id)
              if(data.code===200){
               await this.startEventInfo(this.eventInfo.id)
                this.$message.success('操作成功')
              }
            })
          },
           async changeStartShow(){
               let obj = {
                    eventId:this.eventInfo.id,
                    type:this.radio
               }
               const {data}=await changeStartShow(obj)
               if (data.code===200){
                    await this.getEventInfo(this.eventInfo.id)
                   this.$message.success('操作成功')
               }
            },
             changePosition(id,type){
              this.$confirm((type===1?'提前':'退后')+'当前选手, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async () => {
                let obj = {
                  personnelId:id,
                  type:type
                }
                const {data} = await changePosition(obj)
                if(data.code===200){
                  this.startEventInfo(this.eventInfo.id)
                  this.$message.success('操作成功')
                }
              })

            },
            async startEventInfo(id){
                const {data} = await startEventInfo(id)
                if(data.code===200){
                    this.currentPersonnel = data.data.currentPersonnel
                    this.personnelList=data.data.personnelList
                    this.judgeList=data.data.judgeList;
                    this.avgScore1=data.data.avgScore1;
                    this.avgScore2=data.data.avgScore2;
                    this.finalScore=data.data.finalScore;
                }
            },
            getInEventOver() {
              this.$confirm('结束比赛, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async () => {
                //判断评委打分是否完毕和选手是否全部比赛结束
                const{data}=await validEventOver(this.eventInfo.id)
                if (data.code===200){
                  this.$router.push('/eventOver')
                }
              })
            },
            //查询比赛信息
            async getEventInfo(id) {
                const {data} = await findById(id);
                if (data.code === 200) {
                    this.eventInfo = data.data
                    this.radio = this.eventInfo.startShow
                    if (this.roleFlag === '3'){
                        if (this.eventInfo.startStatus === 2) {
                            this.$router.push('/drawControl')
                        }
                        if (this.eventInfo.startStatus === 3) {
                            this.$router.push('/eventControl')
                        }
                        if (this.eventInfo.startStatus === 1) {
                            this.$router.push('/hostPage')
                        }
                        if (this.eventInfo.startStatus === 4) {
                            this.$router.push('/eventOver')
                        }
                    }
                    if (this.roleFlag === '4'){
                        if (this.eventInfo.startStatus===1){
                            this.$router.push('/beforeShow')
                        }
                        if (this.eventInfo.startStatus===2){
                            this.$router.push('/drawSort')
                        }
                        if (this.eventInfo.startStatus===3){
                            this.$router.push('/eventShow')
                        }
                        if (this.eventInfo.startStatus===4){
                            this.$router.push('/showOver')
                        }
                    }

                }
            }
        }
    }
</script>

<style scoped lang="less">
    .contain {
        width: 100%;
        height: 100%;
        overflow: auto;

        .contain-player {
            width: 100%;
            height: 300px;
            background: url("../../assets/images/hostBanner.png") no-repeat;
            background-size: 100% 100%;
            background-attachment: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .player-header {
                width: 120px;
                height: 120px;
                margin: 20px 0;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .player-title {
                margin: 20px 0;
                font-weight: bold;
                font-size: 20px;
            }

            .player-about {
                margin: 20px 0;
                width: 50%;
                font-weight: bold;
                font-size: 16px;
                overflow: hidden;
            }
        }

        .contain-judge {
            width: 100%;
            margin: 20px 0;
            padding: 20px 0;
            box-sizing: border-box;
            /*height: 500px;*/
            background: url("../../assets/images/hostBanner.png") no-repeat;
            background-size: 100% 100%;
            background-attachment: fixed;
            display: flex;
            align-items: center;
            justify-content: center;

            .judge-table {
                width: 80%;
                height: 100%;

                .tab-top {
                    width: 100%;
                    height: 50px;
                    background-color: #b24040;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                }

                .tab-content {
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e3c2c2;
                    border-top: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }

        .contain-set {
            width: 100%;
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .set-box {
                width: 60%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                    background-color: #b24040;
                    border-color: #b24040;
                }
            }

        }
    }
</style>
