// 导入 request
import request from '../utils/request'

// 查询当前比赛选手
export const findByEventId = id => {
    return request({
        method: 'get',
        url: '/personnel/findByEventId?id='+id
    })
}

//改变当前选手位置
export const changePosition = data => {
    return request({
        method: 'post',
        url: '/personnel/changePosition',
        data
    })
}
